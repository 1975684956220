<template>
  <ais-pagination>
    <template
      v-slot="{
        currentRefinement,
        nbPages,
        pages,
        isFirstPage,
        isLastPage,
        refine,
      }"
    >
      <v-container fluid>
        <v-row no-gutters>
          <v-col cols="12" md="2" class="text-center">
            <v-btn
              v-if="!isFirstPage"
              @click.prevent="navigate(refine, 0)"
              class="ma-1"
              :small="$vuetify.breakpoint.mdAndDown"
              fab
            >
              <v-icon>mdi-chevron-triple-left</v-icon>
            </v-btn>
            <v-btn
              v-if="!isFirstPage"
              @click.prevent="navigate(refine, currentRefinement - 1)"
              class="ma-1"
              :small="$vuetify.breakpoint.mdAndDown"
              fab
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col class="text-center">
            <v-btn
              v-for="page in pages"
              :key="page"
              :style="{
                fontWeight: page === currentRefinement ? 'bold' : '',
              }"
              @click.prevent="navigate(refine, page)"
              :class="{
                'ma-1': true,
                'v-btn--active': page === currentRefinement,
                'font-weight-bold': page === currentRefinement,
              }"
              :small="$vuetify.breakpoint.mdAndDown"
              fab
            >
              {{ page + 1 }}
            </v-btn>
          </v-col>
          <v-col cols="12" md="2" class="text-center">
            <v-btn
              v-if="!isLastPage"
              @click.prevent="navigate(refine, currentRefinement + 1)"
              class="ma-1"
              :small="$vuetify.breakpoint.mdAndDown"
              fab
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn
              v-if="!isLastPage"
              @click.prevent="navigate(refine, nbPages - 1)"
              class="ma-1"
              :small="$vuetify.breakpoint.mdAndDown"
              fab
            >
              <v-icon>mdi-chevron-triple-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </ais-pagination>
</template>

<script>
import { AisPagination } from "vue-instantsearch";
import { smoothScrollToTop } from "@/utils/smooth-scroll";

export default {
  Name: "FlowSearchPagination",
  components: {
    AisPagination,
  },
  setup() {
    const navigate = (refine, page) => {
      refine(page);
      setTimeout(() => smoothScrollToTop(), 300);
      return;
    };
    return {
      navigate,
    };
  },
};
</script>
