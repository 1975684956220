<template>
  <v-row :class="{}">
    <AddFlowToListDialog
      :lists="mySavedLists"
      :flow="selectedFlow"
      :userId="activeUserId"
      v-model="showAddFlowToListDialog"
      @close="showAddFlowToListDialog = false"
    />
    <CreateNewSavedSearchDialog v-model="showCreateNewSavedSearchDialog" />
    <LoadingContainer label="Loading Search Client..." :loading="!ready">
      <ais-instant-search
        :search-client="searchClient"
        :index-name="indexName"
        :routing="routing"
      >
        <ais-state-results v-slot="{ results }">
          {{ updateCurrentResults(results) }}
        </ais-state-results>
        <v-row>
          <v-col class="text-center">
            <ais-search-box :autofocus="true">
              <template v-slot="{ currentRefinement, isSearchStalled, refine }">
                <v-text-field
                  ref="searchbox"
                  type="search"
                  name="search"
                  :value="currentQuery"
                  @input="refineSearch(refine, $event)"
                  label="Flow Search"
                  :loading="isSearchStalled"
                  autofocus
                  outlined
                >
                  <template v-slot:append-outer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-show="canResetSearch()"
                          @click="resetSearch()"
                          v-bind="attrs"
                          v-on="on"
                          color="red"
                        >
                          mdi-restart
                        </v-icon>
                      </template>
                      <span>Reset</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </template>
            </ais-search-box>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-btn class="w-100" @click="saveSearchSettings">
              Save Flow Search
            </v-btn>
          </v-col>
          <v-col>
            <v-row align="center" class="no-gutters px-4" dense md3>
              <v-col md3>
                <ais-stats></ais-stats>
              </v-col>
              <v-col class="text-end" md3>
                <div
                  style="
                    display: inline-flex;
                    align-items: center;
                    padding-top: 8px;
                    padding-right: 8px;
                  "
                >
                  <v-btn
                    :disabled="!selectedFlows.length"
                    @click="saveFlowsToList"
                    :style="{
                      visibility: selectedFlows.length ? 'visible' : 'hidden',
                    }"
                  >
                    Save Flows ({{ selectedFlows.length }})
                  </v-btn>
                  <v-checkbox
                    :input-value="allSelected"
                    @change="manageSelected"
                    hide-details
                    color="primary"
                    style="
                      margin-top: 4px;
                      margin-left: 16px;
                      padding-top: 0;
                      height: 24px;
                    "
                    :ripple="false"
                  ></v-checkbox>
                  <v-fade-transition>
                    <v-btn
                      icon
                      :style="{
                        marginTop: '4px',
                        paddingTop: '0',
                        height: '24px',
                        visibility: selectedFlows.length ? 'visible' : 'hidden',
                      }"
                      @click="selectedFlows = []"
                      aria-label="deselectAll"
                    >
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </v-fade-transition>
                </div>
              </v-col>
            </v-row>
            <v-btn
              outlined
              block
              class="hidden-md-and-up mt-2"
              @click="scrollToResults"
            >
              <v-icon class="mx-auto">mdi-transfer-down</v-icon>
              <div class="px-5">Scroll to results</div>
              <v-icon class="mx-auto">mdi-transfer-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-card>
              <div v-for="item in facetFields" :key="item.field">
                <v-card-title>
                  {{ item.label }}
                </v-card-title>
                <v-card-text>
                  <ais-refinement-list
                    class=""
                    :limit="item.limit || 10"
                    :searchable="item.searchable || false"
                    :searchable-placeholder="
                      item.searchablePlaceholder || 'Search...'
                    "
                    :attribute="item.field"
                    :show-more="item.showMore || false"
                    :show-more-limit="item.showMoreLimit || 10"
                  />
                </v-card-text>
              </div>
            </v-card>
          </v-col>
          <v-col ref="scrollElement" cols="12" md="9">
            <v-row dense>
              <ais-hits :transformItems="transformHits">
                <template v-slot:item="{ item }">
                  <v-col cols="12" md="12">
                    <!-- <v-checkbox href="#" :on-icon="'mdi-bookmark'" :off-icon="'mdi-bookmark-outline'" class="align-right" label="" @click="saveFlowtoList(item)" labelPosition='After' /> -->
                    <v-card
                      :to="{
                        name: 'FlowViewer',
                        params: { flowId: item.id },
                      }"
                      class="pb-2"
                      elevation="4"
                    >
                      <v-card-title
                        :to="{
                          name: 'FlowViewer',
                          params: { flowId: item.id },
                        }"
                        class="headline"
                        style=""
                      >
                        <ais-highlight
                          attribute="buffer.facility.code"
                          :hit="item"
                        >
                        </ais-highlight>
                        :&nbsp;
                        <ais-highlight
                          attribute="buffer.description"
                          :hit="item"
                        ></ais-highlight>
                        <div
                          style="
                            margin-left: auto;
                            display: flex;
                            align-items: flex-end;
                          "
                        >
                          <v-checkbox
                            :input-value="
                              selectedFlows.some((flow) => flow.id == item.id)
                            "
                            @change="selectFlow(item)"
                            hide-details
                            color="primary"
                            style="
                              margin-top: 0;
                              padding-top: 6px;
                              height: 36px;
                            "
                            :ripple="false"
                          ></v-checkbox>
                          <v-btn
                            icon
                            href="#"
                            @click.stop="saveFlowToList(item)"
                            aria-label="Save Flow"
                          >
                            <v-icon>mdi-bookmark</v-icon>
                          </v-btn>
                        </div>
                      </v-card-title>
                      <template v-for="(fieldGroup, i) in fieldsView">
                        <v-card-text v-if="fieldGroup.type === 'text'" :key="i">
                          <v-row>
                            <v-col
                              v-for="field in fieldGroup.fields"
                              :key="field.field"
                              :cols="field.cols || 6"
                              :md="field.md || 4"
                            >
                              <strong>
                                {{ field.label }}
                              </strong>
                              <div v-if="field.render">
                                {{ field.render(get(item, field.field)) }}
                              </div>
                              <div
                                v-else-if="
                                  field.isArray &&
                                  get(item, field.field) &&
                                  get(item, field.field).length
                                "
                              >
                                <span
                                  v-for="(itemValue, i2) in get(
                                    item,
                                    field.field
                                  )"
                                  :key="i2"
                                >
                                  <ais-highlight
                                    :attribute="`${field.field}.${i2}`"
                                    :hit="item"
                                  >
                                  </ais-highlight>
                                  <span
                                    v-if="
                                      i2 < get(item, field.field).length - 1
                                    "
                                    >,&nbsp;</span
                                  >
                                </span>
                              </div>
                              <div v-else-if="field.isArray">
                                <span>none</span>
                              </div>
                              <div v-else>
                                <ais-highlight
                                  :attribute="field.field"
                                  :hit="item"
                                ></ais-highlight>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <template
                          v-else-if="
                            fieldGroup.type === 'array' &&
                            pathHasHighlightChildren(fieldGroup.field, item)
                          "
                        >
                          <div :key="i">
                            <v-card-title>
                              {{ fieldGroup.label }}
                            </v-card-title>
                            <v-container class="pt-0">
                              <v-row>
                                <v-col
                                  cols="12"
                                  v-for="(itemValue, i2) in item[
                                    fieldGroup.field
                                  ]"
                                  :key="i2"
                                  v-show="
                                    pathHasHighlightChildren(
                                      fieldGroup.field,
                                      item,
                                      i2
                                    )
                                  "
                                  class="py-1"
                                >
                                  <v-card outlined>
                                    <v-card-text class="px-5 py-1">
                                      <v-row>
                                        <v-col
                                          v-for="arrayFieldItem in fieldGroup.fields"
                                          :key="arrayFieldItem.field"
                                          :cols="arrayFieldItem.cols || 6"
                                          :md="arrayFieldItem.md || 3"
                                          v-show="
                                            !arrayFieldItem.onlyOnHighlight ||
                                            pathHasHighlight(
                                              arrayFieldItem.field.replace(
                                                '[]',
                                                i2
                                              ),
                                              item
                                            )
                                          "
                                        >
                                          <strong
                                            v-show="
                                              !!get(
                                                item,
                                                arrayFieldItem.field.replace(
                                                  '[]',
                                                  i2
                                                )
                                              )
                                            "
                                          >
                                            {{ arrayFieldItem.label }}
                                          </strong>
                                          <div>
                                            <ais-highlight
                                              :attribute="
                                                arrayFieldItem.field.replace(
                                                  '[]',
                                                  i2
                                                )
                                              "
                                              :hit="item"
                                            ></ais-highlight>
                                          </div>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </v-container>
                          </div>
                        </template>
                      </template>
                      <!-- <v-card-actions>
                        <v-btn
                          :to="{
                            name: 'FlowViewer',
                            params: { flowId: item.id },
                          }"
                          text
                        >
                          View
                        </v-btn>
                      </v-card-actions> -->
                    </v-card>
                  </v-col>
                </template>
              </ais-hits>
            </v-row>
            <FlowSearchPagination />
          </v-col>
        </v-row>
        <ais-configure :filters="queryFilters" />
      </ais-instant-search>
    </LoadingContainer>
  </v-row>
</template>

<script>
import { computed, getCurrentInstance, nextTick, ref, watch } from "vue-demi";
import { hasIn, get, debounce } from "lodash-es";
import {
  AisInstantSearch,
  AisSearchBox,
  AisHits,
  AisHighlight,
  AisStats,
  AisRefinementList,
  AisConfigure,
  AisStateResults,
} from "vue-instantsearch";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { api } from "../utils/feathers-api";
import { useRouter, useRoute, useRouteQuery } from "../utils/useRouter";
import LoadingContainer from "./LoadingContainer.vue";
import "instantsearch.css/themes/reset.css";
import FlowSearchPagination from "./FlowSearchPagination.vue";
import { useAuth } from "../store/auth.pinia";
// import AddFlowToListModal from "./AddFlowToListModal.vue";
import { useUserSavedLists } from "../store/userSavedLists.pinia";
import { useFind } from "feathers-pinia";
import AddFlowToListDialog from "./AddFlowToListDialog.vue";
import CreateNewSavedSearchDialog from "./CreateNewSavedSearchDialog.vue";
//TODO ADD Algolia Search Helper: https://instantsearchjs.netlify.app/algoliasearch-helper-js/gettingstarted#first-search
export default {
  name: "FlowSearch",
  components: {
    LoadingContainer,
    AisInstantSearch,
    AisSearchBox,
    AisHits,
    AisHighlight,
    AisStats,
    AisRefinementList,
    FlowSearchPagination,
    AisConfigure,
    AddFlowToListDialog,
    CreateNewSavedSearchDialog,
    AisStateResults,
  },
  setup() {
    const instance = getCurrentInstance();
    const ready = ref(false);
    const indexName = ref(null);
    const typesenseAdapter = ref(null);
    const connectionDetailsResponse = ref(null);
    const searchbox = ref(null);
    const scrollElement = ref(null);
    const currentQuery = ref("");
    const router = useRouter();
    const currentRoute = useRoute();
    const componentName = computed(() => instance.proxy.$options.name);
    const authStore = useAuth();
    const showAddFlowToListDialog = ref(false);
    const userSavedListsStore = useUserSavedLists();
    const selectedFlow = ref([]);
    const activeUserId = ref(authStore.userId);
    const showCreateNewSavedSearchDialog = ref(false);

    const allSelected = ref(false);
    const selectedFlows = ref([]);
    const currentResults = ref([]);

    // checks to see if all the items on the current page are selected
    const areAllSelected = computed(() => {
      return (
        currentResults.value.length &&
        currentResults.value.every((item) =>
          selectedFlows.value.some((flow) => flow.id === item.id)
        )
      );
    });

    watch(
      [currentResults, selectedFlows],
      () => {
        allSelected.value = areAllSelected.value;
      },
      {
        deep: true,
      }
    );

    const deselectFlow = (flow) => {
      selectedFlows.value = selectedFlows.value.filter(
        (item) => item.id !== flow.id
      );
    };
    const selectFlow = (flow) => {
      if (selectedFlows.value.some((sflow) => sflow.id === flow.id)) {
        deselectFlow(flow);
      } else {
        selectFlows(flow);
      }
    };

    // store search results in local state to compare against selections
    const updateCurrentResults = (results) => {
      currentResults.value = results?.hits.map((hit) => ({ ...hit }));
    };

    const selectFlows = (flow = null) => {
      const unique = new Map();

      // Add all selectedFlows to the Map
      selectedFlows.value.forEach((obj) => unique.set(obj.id, obj));

      // Add single entry if defined, or all currentResults, checking for duplicates
      if (flow) {
        if (!unique.has(flow.id)) {
          unique.set(flow.id, flow);
        }
      } else {
        currentResults.value.forEach((obj) => {
          if (!unique.has(obj.id)) {
            unique.set(obj.id, obj);
          }
        });
      }

      // Convert the Map values back to an array
      selectedFlows.value = Array.from(unique.values());
    };

    const deselectAll = () => {
      currentResults.value.forEach((flow) => deselectFlow(flow));
    };

    const manageSelected = () => {
      if (allSelected.value) {
        deselectAll();
      } else {
        selectFlows();
      }
    };

    // get current user's id
    const mySavedFlowsParams = computed(() => {
      return {
        query: {
          user_id: activeUserId.value,
        },
      };
    });

    const { items: mySavedLists } = useFind({
      model: userSavedListsStore.Model,
      params: mySavedFlowsParams,
    });

    const scrollToResults = debounce(() => {
      if (scrollElement.value) {
        scrollElement.value.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100);

    const facetFields = ref([
      {
        label: "Facilities",
        field: "facility_codes",
        limit: 20,
      },
      {
        label: "Initiation Type",
        field: "initiation_type",
        limit: 20,
      },
      {
        label: "Schedule Station",
        field: "schedule_stations",
        searchable: true,
        searchablePlaceholder: "Search Schedule Stations...",
      },
      {
        label: "Waypoint",
        field: "waypoints",
        searchable: true,
        searchablePlaceholder: "Search Waypoints...",
        // limit: 15,
        // showMore: true,
        // showMoreLimit: 30,
      },
      {
        label: "Processes",
        field: "processes",
        searchable: true,
        searchablePlaceholder: "Search Processes...",
        limit: 15,
        // showMore: true,
        // showMoreLimit: 30,
      },
      {
        label: "Flow Type",
        field: "flow_details.type",
      },
    ]);

    const searchClient = computed(() => {
      if (typesenseAdapter.value) {
        return typesenseAdapter.value.searchClient;
      }
      return null;
    });

    const collectionFields = computed(() => {
      return connectionDetailsResponse.value?.fields ?? [];
    });

    // Fetch the connection details from the server
    const fetchConnectionDetails = () => {
      const typesenseService = api.service("typesense");
      return typesenseService.get("api-key").catch((err) => {
        console.error("Search: Error fetching connection details", err);
        return null;
      });
    };

    const createAdapter = async (connectionDetails) => {
      console.log("Search: Creating adapter", connectionDetails);
      connectionDetailsResponse.value = connectionDetails;
      // Validate the connection details
      if (!connectionDetails)
        throw new Error("Search: Connection details not found");
      if (!connectionDetails?.apiKey?.length)
        throw new Error("Search: API key not found");
      if (!connectionDetails?.nodes?.length)
        throw new Error("Search: Server not defined");
      // Create the adapter
      const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
          apiKey: connectionDetails.apiKey,
          nodes: connectionDetails.nodes ?? [],
          cacheSearchResultsForSeconds: 10,
        },
        additionalSearchParameters: {
          // Search by all the fields available in the collection
          query_by: collectionFields.value.join(","),
          // Add weights
          query_by_weights: connectionDetails.fieldWeights?.join(","),
          per_page: 20,
        },
        facetableFieldsWithSpecialCharacters: [
          "initiation_type",
          "waypoints",
          "schedule_stations",
          "processes",
        ],
      });
      // Set the adapter
      typesenseAdapter.value = typesenseInstantsearchAdapter;
    };

    // Fetch the connection details
    fetchConnectionDetails()
      .then((response) => {
        // Create the adapter
        createAdapter(response);
        // Set the index name
        indexName.value = response?.indexes?.flows || "flows";
        // We're ready!
        ready.value = true;
      })
      .catch((err) => {
        console.error("Search: Error during setup", err);
      });

    const unbouncedRefineSearch = (refine, query) => {
      refine(query ?? "");
    };

    const debouncedRefineSearch = debounce(unbouncedRefineSearch, 200, {
      leading: false,
      trailing: true,
    });

    const refineSearch = (refine, query) => {
      currentQuery.value = query || "";
      debouncedRefineSearch(refine, currentQuery.value);
    };

    const fieldsView = ref([
      {
        type: "text",
        fields: [
          // {
          //   label: "Dest Buffer Facility",
          //   field: "buffer.facility.code",
          //   cols: 4,
          //   md: 4,
          // },
          // {
          //   label: "Dest Buffer Name",
          //   field: "buffer.description",
          //   cols: 4,
          //   md: 4,
          // },
          {
            label: "Destination Part",
            field: "buffer.part.part_number",
            cols: 4,
          },
          {
            label: "Part Desc",
            field: "buffer.part.description",
            cols: 4,
          },
          {
            label: "Initiation Type",
            field: "initiation_type",
          },
          {
            label: "Initiating Part",
            field: "initiation_part_number",
          },
          {
            label: "Initiating Part Desc",
            field: "initiation_part_description",
          },
          {
            label: "Facilities",
            field: "facility_codes",
            isArray: true,
          },
          {
            label: "Workcenters",
            field: "workcenters",
            isArray: true,
          },
          {
            label: "Processes",
            field: "processes",
            isArray: true,
          },
          {
            label: "Source Buffer Parts",
            field: "source_buffer_parts",
            isArray: true,
          },
          {
            label: "Schedule Stations",
            field: "schedule_stations",
            isArray: true,
          },
          {
            label: "Waypoints",
            field: "waypoints",
            isArray: true,
          },
          {
            label: "Flow Details",
            field: "flow_details",
            render: (value) => {
              // Show the count
              return value?.length || 0;
            },
            cols: 4,
          },
        ],
      },
      // {
      //   type: "array",
      //   label: "Flow Details",
      //   field: "flow_details",
      //   fields: [
      //     {
      //       type: "label",
      //       label: "Type",
      //       field: "flow_details.[].type",
      //       cols: 6,
      //       md: 3,
      //     },
      //     {
      //       label: "Facility",
      //       field: "flow_details.[].facility.code",
      //       cols: 6,
      //       md: "auto",
      //     },
      //     {
      //       label: "Workcenter",
      //       field: "flow_details.[].operation.process.workcenter.title",
      //       // md: "auto",
      //     },
      //     {
      //       label: "Process",
      //       field: "flow_details.[].operation.process.title",
      //       // md: "auto",
      //     },
      //     {
      //       label: "Part Number",
      //       field: "flow_details.[].buffer.part.part_number",
      //       md: "auto",
      //       onlyOnHighlight: true,
      //     },
      //   ],
      // },
    ]);

    const flowIdFilters = useRouteQuery("flowIdFilters", []);

    const queryFilters = computed(() => {
      return flowIdFilters.value.length
        ? "id:[" + flowIdFilters.value.join(",") + "]"
        : "";
    });

    // Query parameter values to be persisted on state updates
    const persistedQueryParams = computed(() => {
      return {
        flowIdFilters: flowIdFilters.value,
      };
    });

    // Reset query when filters are provided
    watch(
      () => flowIdFilters.value,
      (val, oldVal) => {
        if (!oldVal?.length && val?.length) {
          searchbox.value.reset();
        }
      }
    );

    /**
     * ROUTING
     */
    const isCurrentRoute = computed(
      () => currentRoute.value.name === componentName.value
    );
    const routing = {
      router: {
        read() {
          return {
            [indexName.value]: currentRoute.value?.query ?? {},
          };
        },
        write(routeState) {
          // Wait for nextTick, so computed values are updated
          nextTick(() => {
            const newRoute = {
              query: {
                ...routeState[indexName.value],
                ...persistedQueryParams.value,
              },
            };
            // If routes are the same, don't push
            if (
              currentRoute.value.fullPath ===
              router.resolve(newRoute).route.fullPath
            )
              return;
            router.push(newRoute);
          });
        },
        createURL(routeState) {
          return router.resolve({
            query: routeState[indexName.value],
          }).href;
        },
        onUpdate(cb) {
          if (typeof window === "undefined") return;
          // Make sure the current query is set
          const setQuery = () => {
            currentQuery.value = currentRoute.value?.query?.query || "";
          };
          setQuery();

          const cbMethod = () => () => {
            if (!isCurrentRoute.value) return;
            setQuery();
            cb(this.read());
          };

          this._removeAfterEach = router.afterEach(cbMethod());

          if (this._onPopState)
            window.removeEventListener("popstate", this._onPopState);

          this._onPopState = cbMethod();
          window.addEventListener("popstate", this._onPopState);
        },
        dispose() {
          if (typeof window === "undefined") {
            return;
          }
          if (this._onPopState) {
            window.removeEventListener("popstate", this._onPopState);
          }
          if (this._removeAfterEach) {
            this._removeAfterEach();
          }
        },
      },
    };

    // Check if search can be reset
    const canResetSearch = () => {
      return Object.keys(currentRoute.value?.query ?? {}).length;
    };

    // Reset search by clearing the query
    const resetSearch = () => {
      searchbox.value?.reset();
      router.push({ query: {} });
    };

    const transformHits = (hits) => hits;

    const pathHasHighlight = (fieldPath, hit) => {
      const result = get(hit._highlightResult, fieldPath);
      return result && result.matchedWords?.length;
    };

    const getMatchResultChildren = (result) => {
      if (!result) return [];
      return Object.keys(result).reduce((acc, key) => {
        // If this item is an object, but not a match result, recurse
        if (typeof result[key] === "object" && !result[key].matchedWords)
          return [...acc, ...getMatchResultChildren(result[key])];
        if (!result[key].matchedWords?.length) return acc;
        return [...acc, result[key]];
      }, []);
    };

    const pathHasHighlightChildren = (fieldPath, hit, index = false) => {
      const item = get(
        hit._highlightResult,
        index === false ? fieldPath : `${fieldPath}.${index}`
      );
      if (!item) return false;
      if (Array.isArray(item))
        return item.some((item, i) =>
          pathHasHighlightChildren(fieldPath, hit, i)
        );
      const matches = getMatchResultChildren(item);
      return matches.length > 0;
    };

    const saveFlowToList = (flow) => {
      selectedFlow.value = [flow];
      showAddFlowToListDialog.value = true;
    };

    const saveFlowsToList = () => {
      selectedFlow.value = selectedFlows.value;
      showAddFlowToListDialog.value = true;
    };

    const saveSearchSettings = () => {
      showCreateNewSavedSearchDialog.value = true;
    };

    return {
      activeUserId,
      allSelected,
      areAllSelected,
      canResetSearch,
      collectionFields,
      currentQuery,
      currentResults,
      get,
      hasIn,
      indexName,
      facetFields,
      fieldsView,
      manageSelected,
      mySavedLists,
      pathHasHighlight,
      pathHasHighlightChildren,
      queryFilters,
      ready,
      refineSearch,
      resetSearch,
      routing,
      saveFlowToList,
      saveFlowsToList,
      saveSearchSettings,
      scrollElement,
      scrollToResults,
      searchbox,
      searchClient,
      selectFlow,
      selectFlows,
      selectedFlow,
      selectedFlows,
      showAddFlowToListDialog,
      showCreateNewSavedSearchDialog,
      transformHits,
      updateCurrentResults,
    };
  },
};
</script>

<style lang="scss">
.ais-RefinementList {
  .ais-RefinementList-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .ais-RefinementList-count {
    border: 1px solid rgb(255 255 255 / 20%);
    border-radius: 5px;
    padding: 0 3px;
  }
}

.ais-Hits-list {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}

.ais-SearchBox-submit {
  display: none;
}

.ais-Hits {
  width: 100%;
}

.ais-SearchBox .ais-SearchBox-input {
  padding: 10px 30px;
  width: 100%;
  font-size: 1.5em;
  border: 1px solid #d6d6e7;
  border-radius: 5px;
}

.ais-RefinementList-searchBox {
  .ais-SearchBox-input {
    padding: 5px 10px;
    width: 100%;
    border: 1px solid #d6d6e7;
    border-radius: 5px;
    margin-bottom: 10px;
  }
}

.theme--dark {
  .ais-RefinementList-searchBox,
  .ais-SearchBox {
    .ais-SearchBox-input {
      color: #d6d6e7;
      // Darken the border
      border-color: #d6d6e761;
    }
  }

  .ais-Hits {
    .ais-Hits-item {
      background: none;
      color: #d6d6e7;
      position: relative;
    }
  }
}

.align-right {
  position: absolute;
  right: 1vw;
  z-index: 2;
}

.headline {
  display: flex;
}
</style>
