var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{class:{}},[_c('AddFlowToListDialog',{attrs:{"lists":_vm.mySavedLists,"flow":_vm.selectedFlow,"userId":_vm.activeUserId},on:{"close":function($event){_vm.showAddFlowToListDialog = false}},model:{value:(_vm.showAddFlowToListDialog),callback:function ($$v) {_vm.showAddFlowToListDialog=$$v},expression:"showAddFlowToListDialog"}}),_c('CreateNewSavedSearchDialog',{model:{value:(_vm.showCreateNewSavedSearchDialog),callback:function ($$v) {_vm.showCreateNewSavedSearchDialog=$$v},expression:"showCreateNewSavedSearchDialog"}}),_c('LoadingContainer',{attrs:{"label":"Loading Search Client...","loading":!_vm.ready}},[_c('ais-instant-search',{attrs:{"search-client":_vm.searchClient,"index-name":_vm.indexName,"routing":_vm.routing}},[_c('ais-state-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var results = ref.results;
return [_vm._v(" "+_vm._s(_vm.updateCurrentResults(results))+" ")]}}])}),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('ais-search-box',{attrs:{"autofocus":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentRefinement = ref.currentRefinement;
var isSearchStalled = ref.isSearchStalled;
var refine = ref.refine;
return [_c('v-text-field',{ref:"searchbox",attrs:{"type":"search","name":"search","value":_vm.currentQuery,"label":"Flow Search","loading":isSearchStalled,"autofocus":"","outlined":""},on:{"input":function($event){return _vm.refineSearch(refine, $event)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.canResetSearch()),expression:"canResetSearch()"}],attrs:{"color":"red"},on:{"click":function($event){return _vm.resetSearch()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-restart ")])]}}],null,true)},[_c('span',[_vm._v("Reset")])])]},proxy:true}],null,true)})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-btn',{staticClass:"w-100",on:{"click":_vm.saveSearchSettings}},[_vm._v(" Save Flow Search ")])],1),_c('v-col',[_c('v-row',{staticClass:"no-gutters px-4",attrs:{"align":"center","dense":"","md3":""}},[_c('v-col',{attrs:{"md3":""}},[_c('ais-stats')],1),_c('v-col',{staticClass:"text-end",attrs:{"md3":""}},[_c('div',{staticStyle:{"display":"inline-flex","align-items":"center","padding-top":"8px","padding-right":"8px"}},[_c('v-btn',{style:({
                    visibility: _vm.selectedFlows.length ? 'visible' : 'hidden',
                  }),attrs:{"disabled":!_vm.selectedFlows.length},on:{"click":_vm.saveFlowsToList}},[_vm._v(" Save Flows ("+_vm._s(_vm.selectedFlows.length)+") ")]),_c('v-checkbox',{staticStyle:{"margin-top":"4px","margin-left":"16px","padding-top":"0","height":"24px"},attrs:{"input-value":_vm.allSelected,"hide-details":"","color":"primary","ripple":false},on:{"change":_vm.manageSelected}}),_c('v-fade-transition',[_c('v-btn',{style:({
                      marginTop: '4px',
                      paddingTop: '0',
                      height: '24px',
                      visibility: _vm.selectedFlows.length ? 'visible' : 'hidden',
                    }),attrs:{"icon":"","aria-label":"deselectAll"},on:{"click":function($event){_vm.selectedFlows = []}}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1)],1)])],1),_c('v-btn',{staticClass:"hidden-md-and-up mt-2",attrs:{"outlined":"","block":""},on:{"click":_vm.scrollToResults}},[_c('v-icon',{staticClass:"mx-auto"},[_vm._v("mdi-transfer-down")]),_c('div',{staticClass:"px-5"},[_vm._v("Scroll to results")]),_c('v-icon',{staticClass:"mx-auto"},[_vm._v("mdi-transfer-down")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',_vm._l((_vm.facetFields),function(item){return _c('div',{key:item.field},[_c('v-card-title',[_vm._v(" "+_vm._s(item.label)+" ")]),_c('v-card-text',[_c('ais-refinement-list',{attrs:{"limit":item.limit || 10,"searchable":item.searchable || false,"searchable-placeholder":item.searchablePlaceholder || 'Search...',"attribute":item.field,"show-more":item.showMore || false,"show-more-limit":item.showMoreLimit || 10}})],1)],1)}),0)],1),_c('v-col',{ref:"scrollElement",attrs:{"cols":"12","md":"9"}},[_c('v-row',{attrs:{"dense":""}},[_c('ais-hits',{attrs:{"transformItems":_vm.transformHits},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                    var item = ref.item;
return [_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"pb-2",attrs:{"to":{
                      name: 'FlowViewer',
                      params: { flowId: item.id },
                    },"elevation":"4"}},[_c('v-card-title',{staticClass:"headline",attrs:{"to":{
                        name: 'FlowViewer',
                        params: { flowId: item.id },
                      }}},[_c('ais-highlight',{attrs:{"attribute":"buffer.facility.code","hit":item}}),_vm._v(" :  "),_c('ais-highlight',{attrs:{"attribute":"buffer.description","hit":item}}),_c('div',{staticStyle:{"margin-left":"auto","display":"flex","align-items":"flex-end"}},[_c('v-checkbox',{staticStyle:{"margin-top":"0","padding-top":"6px","height":"36px"},attrs:{"input-value":_vm.selectedFlows.some(function (flow) { return flow.id == item.id; }),"hide-details":"","color":"primary","ripple":false},on:{"change":function($event){return _vm.selectFlow(item)}}}),_c('v-btn',{attrs:{"icon":"","href":"#","aria-label":"Save Flow"},on:{"click":function($event){$event.stopPropagation();return _vm.saveFlowToList(item)}}},[_c('v-icon',[_vm._v("mdi-bookmark")])],1)],1)],1),_vm._l((_vm.fieldsView),function(fieldGroup,i){return [(fieldGroup.type === 'text')?_c('v-card-text',{key:i},[_c('v-row',_vm._l((fieldGroup.fields),function(field){return _c('v-col',{key:field.field,attrs:{"cols":field.cols || 6,"md":field.md || 4}},[_c('strong',[_vm._v(" "+_vm._s(field.label)+" ")]),(field.render)?_c('div',[_vm._v(" "+_vm._s(field.render(_vm.get(item, field.field)))+" ")]):(
                                field.isArray &&
                                _vm.get(item, field.field) &&
                                _vm.get(item, field.field).length
                              )?_c('div',_vm._l((_vm.get(
                                  item,
                                  field.field
                                )),function(itemValue,i2){return _c('span',{key:i2},[_c('ais-highlight',{attrs:{"attribute":((field.field) + "." + i2),"hit":item}}),(
                                    i2 < _vm.get(item, field.field).length - 1
                                  )?_c('span',[_vm._v(", ")]):_vm._e()],1)}),0):(field.isArray)?_c('div',[_c('span',[_vm._v("none")])]):_c('div',[_c('ais-highlight',{attrs:{"attribute":field.field,"hit":item}})],1)])}),1)],1):(
                          fieldGroup.type === 'array' &&
                          _vm.pathHasHighlightChildren(fieldGroup.field, item)
                        )?[_c('div',{key:i},[_c('v-card-title',[_vm._v(" "+_vm._s(fieldGroup.label)+" ")]),_c('v-container',{staticClass:"pt-0"},[_c('v-row',_vm._l((item[
                                  fieldGroup.field
                                ]),function(itemValue,i2){return _c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
                                  _vm.pathHasHighlightChildren(
                                    fieldGroup.field,
                                    item,
                                    i2
                                  )
                                ),expression:"\n                                  pathHasHighlightChildren(\n                                    fieldGroup.field,\n                                    item,\n                                    i2\n                                  )\n                                "}],key:i2,staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"px-5 py-1"},[_c('v-row',_vm._l((fieldGroup.fields),function(arrayFieldItem){return _c('v-col',{directives:[{name:"show",rawName:"v-show",value:(
                                          !arrayFieldItem.onlyOnHighlight ||
                                          _vm.pathHasHighlight(
                                            arrayFieldItem.field.replace(
                                              '[]',
                                              i2
                                            ),
                                            item
                                          )
                                        ),expression:"\n                                          !arrayFieldItem.onlyOnHighlight ||\n                                          pathHasHighlight(\n                                            arrayFieldItem.field.replace(\n                                              '[]',\n                                              i2\n                                            ),\n                                            item\n                                          )\n                                        "}],key:arrayFieldItem.field,attrs:{"cols":arrayFieldItem.cols || 6,"md":arrayFieldItem.md || 3}},[_c('strong',{directives:[{name:"show",rawName:"v-show",value:(
                                            !!_vm.get(
                                              item,
                                              arrayFieldItem.field.replace(
                                                '[]',
                                                i2
                                              )
                                            )
                                          ),expression:"\n                                            !!get(\n                                              item,\n                                              arrayFieldItem.field.replace(\n                                                '[]',\n                                                i2\n                                              )\n                                            )\n                                          "}]},[_vm._v(" "+_vm._s(arrayFieldItem.label)+" ")]),_c('div',[_c('ais-highlight',{attrs:{"attribute":arrayFieldItem.field.replace(
                                                '[]',
                                                i2
                                              ),"hit":item}})],1)])}),1)],1)],1)],1)}),1)],1)],1)]:_vm._e()]})],2)],1)]}}])})],1),_c('FlowSearchPagination')],1)],1),_c('ais-configure',{attrs:{"filters":_vm.queryFilters}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }