<template>
  <v-dialog v-model="dialog" max-width="100%" width="400">
    <CreateNewListDialog
      v-model="createListDialog"
      v-on:list-created="appendSelection"
    />
    <v-card>
      <v-card-title class="text-h5"> Select List(s) </v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="selected"
          :items="listTitles"
          chips
          deletable-chips
          multiple
          label="Lists"
          class="pb-0 mb-0"
          no-data-text="No lists found"
          :menu-props="{
            closeOnContentClick: true,
            openOnClick: false,
          }"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="green lighten-1"
          text
          @click="createListDialog = true"
          style="float: left"
        >
          Create New List
        </v-btn>
        <v-spacer />
        <v-btn color="error" text @click="close"> Cancel </v-btn>
        <v-btn color="green darken-1" text @click="save"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed, watch, nextTick } from "vue-demi";
import { useUserSavedFlows } from "../store/userSavedFlows.pinia";
import CreateNewListDialog from "./CreateNewListDialog.vue";
import { useFind } from "feathers-pinia";
import { computedRef } from "../utils/composables";

export default {
  name: "AddFlowToListDialog",
  props: {
    value: {},
    lists: {
      type: Array,
      default: () => [],
    },
    flow: {
      type: Array,
      default: () => [],
    },
    userId: {
      type: String,
      default: () => "",
    },
  },
  components: {
    CreateNewListDialog,
  },
  setup(props, context) {
    const selected = ref([]);
    const userSavedFlowsStore = useUserSavedFlows();
    const createListDialog = ref(false);
    const initialLists = ref([]);
    // Items that have been selected while the dialog is open
    const selectedWhileOpen = ref([]);
    // Items that have been removed while the dialog is open
    const removedWhileOpen = ref([]);

    const mySavedListsIds = computed(() => {
      return props.lists.map((list) => {
        return list.id;
      });
    });

    const mySavedFlowsParams = computedRef(() => {
      return {
        query: {
          flow_id: { $in: props?.flow?.map((flow) => flow.id) ?? [] },
          user_id: props.userId,
        },
      };
    });

    // get any instances of saved flows with this flow_id
    const { items: mySavedFlows } = useFind({
      model: userSavedFlowsStore.Model,
      params: mySavedFlowsParams,
    });

    // compare the number of selected flows to the number returned per list to see if all of them exist in a list, if so we will show that list
    const validListIds = computed(() => {
      if (!props.flow?.length) return [];
      const flowIdCount = props.flow.length;
      const countMap = new Map();

      for (let flow of mySavedFlows.value) {
        if (!countMap.has(flow.list_id)) {
          countMap.set(flow.list_id, new Set());
        }
        countMap.get(flow.list_id).add(flow.flow_id);
      }

      return (
        Array.from(countMap.entries())
          /* eslint-disable-next-line no-unused-vars */
          .filter(([_, flowIdSet]) => flowIdSet.size === flowIdCount)
          /* eslint-disable-next-line no-unused-vars */
          .map(([listId, _]) => listId)
          .filter((listId) => mySavedListsIds.value.includes(listId))
      );
    });

    const updateSelected = () => {
      // grab the list names from the valid list ids
      const names =
        props.lists
          ?.filter((list) => validListIds.value?.includes(list.id))
          .map((list) => list.title) ?? [];
      // Set the initialLists value to the names from just this filter
      initialLists.value = [...names];
      // Append items that have been selected while the dialog is open
      names.push(...selectedWhileOpen.value);
      // Remove items that have been removed while the dialog is open
      const namesResult = names.filter(
        (name) => !removedWhileOpen.value.includes(name)
      );
      selected.value = [...new Set(namesResult)];
    };

    watch(
      () => validListIds.value,
      () => nextTick(() => updateSelected()),
      {
        deep: true,
        immediate: true,
      }
    );

    const save = () => {
      const selectedLists = props.lists
        .filter((list) => {
          return (
            selected.value.includes(list.title) &&
            !initialLists.value.includes(list.title)
          );
        })
        .map((list) => {
          return list.id;
        });

      if (selectedLists.length > 0) {
        selectedLists.forEach((listId) => {
          // Find all flow_ids for the current listId in mySavedFlows
          const existingFlowIdsForList = mySavedFlows.value
            .filter((savedFlow) => savedFlow.list_id === listId)
            .map((savedFlow) => savedFlow.flow_id);

          // Filter props.flow to exclude flows that are already saved for this listId
          const newFlowsToAdd = props.flow.filter(
            (flow) => !existingFlowIdsForList.includes(flow.id)
          );

          // Add only the new flows to the userSavedFlowsStore
          if (newFlowsToAdd.length > 0) {
            userSavedFlowsStore.addSavedFlows(
              listId,
              newFlowsToAdd,
              props.userId
            );
          }
        });
      }

      close();
    };

    const close = () => {
      // clear the selection
      // selected.value = [];
      context.emit("close");
    };

    const listTitles = computed(() => {
      return props.lists.map((list) => {
        return list.title;
      });
    });

    const dialog = computed({
      get() {
        return this.value;
      },
      set(val) {
        context.emit("input", val);
      },
    });

    watch(
      () => dialog.value,
      () => {
        // Clear "while open" arrays
        selectedWhileOpen.value = [];
        removedWhileOpen.value = [];
      }
    );

    const appendSelection = (item) => {
      // Append the created list to the selectedWhileOpen array
      selectedWhileOpen.value.push(item.title);
      nextTick(() => updateSelected());
    };

    watch(
      () => selected.value,
      (newVal, oldVal) => {
        // Get NEW items that have been selected
        const addedItems = newVal.filter((item) => !oldVal.includes(item));
        // Watch for changes to the selected value and update the removedWhileOpen array
        const removedItems = oldVal.filter((item) => !newVal.includes(item));
        // Append the removed items to the removedWhileOpen array
        removedWhileOpen.value.push(...removedItems);
        // Remove the added items from the removedWhileOpen array
        removedWhileOpen.value = [
          ...new Set(
            removedWhileOpen.value.filter((item) => !addedItems.includes(item))
          ),
        ];
        // Update the selectedWhileOpen array
        selectedWhileOpen.value = [
          ...new Set([...addedItems, ...selectedWhileOpen.value]),
        ];
      }
    );

    return {
      save,
      close,
      selected,
      listTitles,
      dialog,
      createListDialog,
      appendSelection,
    };
  },
};
</script>
