var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ais-pagination',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var currentRefinement = ref.currentRefinement;
    var nbPages = ref.nbPages;
    var pages = ref.pages;
    var isFirstPage = ref.isFirstPage;
    var isLastPage = ref.isLastPage;
    var refine = ref.refine;
return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"2"}},[(!isFirstPage)?_c('v-btn',{staticClass:"ma-1",attrs:{"small":_vm.$vuetify.breakpoint.mdAndDown,"fab":""},on:{"click":function($event){$event.preventDefault();return _vm.navigate(refine, 0)}}},[_c('v-icon',[_vm._v("mdi-chevron-triple-left")])],1):_vm._e(),(!isFirstPage)?_c('v-btn',{staticClass:"ma-1",attrs:{"small":_vm.$vuetify.breakpoint.mdAndDown,"fab":""},on:{"click":function($event){$event.preventDefault();return _vm.navigate(refine, currentRefinement - 1)}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1):_vm._e()],1),_c('v-col',{staticClass:"text-center"},_vm._l((pages),function(page){return _c('v-btn',{key:page,class:{
              'ma-1': true,
              'v-btn--active': page === currentRefinement,
              'font-weight-bold': page === currentRefinement,
            },style:({
              fontWeight: page === currentRefinement ? 'bold' : '',
            }),attrs:{"small":_vm.$vuetify.breakpoint.mdAndDown,"fab":""},on:{"click":function($event){$event.preventDefault();return _vm.navigate(refine, page)}}},[_vm._v(" "+_vm._s(page + 1)+" ")])}),1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"2"}},[(!isLastPage)?_c('v-btn',{staticClass:"ma-1",attrs:{"small":_vm.$vuetify.breakpoint.mdAndDown,"fab":""},on:{"click":function($event){$event.preventDefault();return _vm.navigate(refine, currentRefinement + 1)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1):_vm._e(),(!isLastPage)?_c('v-btn',{staticClass:"ma-1",attrs:{"small":_vm.$vuetify.breakpoint.mdAndDown,"fab":""},on:{"click":function($event){$event.preventDefault();return _vm.navigate(refine, nbPages - 1)}}},[_c('v-icon',[_vm._v("mdi-chevron-triple-right")])],1):_vm._e()],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }