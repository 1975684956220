<template>
  <v-dialog v-model="dialog" max-width="100%" width="400">
    <v-card>
      <v-card-title class="text-h5"> New Saved Search </v-card-title>
      <v-card-text class="pb-0">
        <v-text-field
          :rules="titleRules"
          v-model="newSearchTitle"
          label="Search Title"
          @keyup.enter="create"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="error" text @click="close"> Cancel </v-btn>
        <v-btn color="green darken-1" text @click="create"> Create </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, computed } from "vue-demi";
import { useUserSavedSearches } from "../store/userSavedSearches.pinia";
import { useRouter } from "../utils/useRouter";
import { useStore } from "@/store/app.pinia.js";
import rules from "../utils/rules";

export default {
  name: "CreateNewSavedSearchDialog",
  props: {
    value: {},
  },
  setup(props, context) {
    const appStore = useStore();
    const userSavedSearchesStore = useUserSavedSearches();
    const newSearchTitle = ref("");
    const router = useRouter();

    const dialog = computed({
      get() {
        return this.value;
      },
      set(val) {
        context.emit("input", val);
      },
    });

    const create = () => {
      if (newSearchTitle.value !== "") {
        userSavedSearchesStore.addSavedSearch(
          newSearchTitle.value,
          router.currentRoute.fullPath,
          appStore.activeFacilityId
        );
        close();
      }
    };

    const close = () => {
      newSearchTitle.value = "";
      dialog.value = false;
    };

    return {
      dialog,
      newSearchTitle,
      create,
      close,
      titleRules: [rules.required],
    };
  },
};
</script>
